/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "@/types";
import axios from "axios";

const scholarship = {
  namespaced: true,

  state: {
    memberSignedIn: false,
    member: {} as Member | null
  },

  getters: {
    member(state: any) {
      return state.member;
    },

    memberSignedIn(state: any) {
      return state.memberSignedIn;
    }
  },

  mutations: {
    setToken(state: any, token: string) {
      state.token = token;
    },

    setMemberSignedIn(state: any, isSignedIn: boolean) {
      state.memberSignedIn = isSignedIn;
    },

    setMember(state: any, member: Member) {
      state.member = member;
    }
  },

  actions: {
    async getToken(context: any): Promise<string | null> {
      let returnVal = null;

      try {
        const params = new URLSearchParams();
        params.append("grant_type", "client_credentials");
        params.append("client_id", context.state.clientId);
        params.append("client_secret", context.state.clientSecret);

        const res = await axios.request({
          baseURL: context.state.apiBase,
          url: "token",
          method: "post",
          data: {
            grant_type: "client_credentials",
            client_id: context.state.clientId,
            client_secret: context.state.clientSecret
          },
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        });

        console.log(res.data);
        console.log(res.request);

        if (res.status == 200) {
          returnVal = res.data.access_token;
        }
      } catch (err) {
        console.error(err);
      }

      return returnVal;
    },

    signIn(context: any, member: Member) {
      localStorage.setItem("member", JSON.stringify(member));
      context.commit("setMember", member);
      context.commit("setMemberSignedIn", true);
    },

    signOut(context: any) {
      localStorage.removeItem("member");
      context.commit("setMember", null);
      context.commit("setMemberSignedIn", false);
    }
  }
};

export default scholarship;
