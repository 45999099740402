import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

// Firebase configs
const firebaseConfig = {
  apiKey: "AIzaSyAfqTM_EcvSMFNYEwrJrzd-SXEvk8Vsxzg",
  authDomain: "kahev-org.firebaseapp.com",
  projectId: "kahev-org",
  storageBucket: "kahev-org.appspot.com",
  messagingSenderId: "652739296918",
  appId: "1:652739296918:web:b6bc393ac0cb42a94eccb7",
  measurementId: "G-TY7GM8PRYL"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
