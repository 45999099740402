import Vue from "vue";
import Vuetify from "vuetify";
import tr from "vuetify/src/locale/tr";
import "fontsource-open-sans";
import "fontsource-open-sans/300.css";
import "fontsource-open-sans/400-italic.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";

const color1 = "#de001c";
const color2 = "#6b226d";
const color3 = "#cd0919";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { tr },
    current: "tr"
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: color1,
        secondary: color2,
        accent: color3
      },
      dark: {
        primary: color1,
        secondary: color2,
        accent: color3
      }
    }
  }
});
