import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("../layouts/home/Index.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/home/Index.vue")
      },
      {
        path: "hakkimizda",
        name: "about-us",
        component: () => import("../views/about-us/Index.vue")
      },
      {
        path: "haberler",
        component: () => import("../layouts/news/Index.vue"),
        children: [
          {
            path: "",
            name: "news",
            component: () => import("../views/news/Index.vue")
          },
          {
            path: ":urlCode",
            name: "news-detail",
            component: () => import("../views/news/Detail.vue")
          }
        ]
      },
      {
        path: "basinda-kahev",
        component: () => import("../layouts/news/Index.vue"),
        children: [
          {
            path: "",
            name: "press",
            component: () => import("../views/press/Index.vue")
          },
          {
            path: ":urlCode",
            name: "press-detail",
            component: () => import("../views/press/Detail.vue")
          }
        ]
      },
      {
        path: "burs",
        component: () => import("../layouts/scholarship/Index.vue"),
        children: [
          {
            path: "",
            name: "scholarship",
            component: () => import("../views/scholarship/Index.vue")
          },
          {
            path: "giris/:id?",
            name: "scholarship-signin",
            component: () => import("../views/scholarship/SignIn.vue")
          },
          {
            path: "basvuru",
            component: () =>
              import("../layouts/scholarship/application/Index.vue"),
            children: [
              {
                path: "sec",
                name: "scholarship-select",
                component: () => import("../views/scholarship/Select.vue")
              },
              {
                path: ":urlCode/basvur",
                name: "scholarship-apply",
                component: () => import("../views/scholarship/Apply.vue")
              },
              {
                path: ":id",
                name: "scholarship-edit",
                component: () => import("../views/scholarship/edit/Index.vue")
              }
            ]
          }
        ]
      },
      {
        path: "okul-destegi",
        name: "school-support",
        component: () => import("../views/school-support/Index.vue")
      },
      {
        path: "bagis",
        name: "donation",
        component: () => import("../views/donation/Index.vue")
      },
      {
        path: "kadin-izi",
        name: "magazine",
        component: () => import("../views/magazine/Index.vue")
      },
      {
        path: "vakif-senedi",
        name: "vakif-senedi",
        component: () => import("../views/subinfo/VakifSenedi.vue")
      },
      {
        path: "faaliyet-raporlari",
        name: "faaliyet-raporlari",
        component: () => import("../views/subinfo/FaaliyetRaporlari.vue")
      },
      {
        path: "bagisci-haklari",
        name: "bagisci-haklari",
        component: () => import("../views/subinfo/BagisciHaklari.vue")
      },
      {
        path: "kullanim-sartlari",
        name: "kullanim-sartlari",
        component: () => import("../views/subinfo/KullanimSartlari.vue")
      },
      {
        path: "kvkk-aydinlatma-metni",
        name: "kvkk",
        component: () => import("../views/subinfo/Kvkk.vue")
      },
      {
        path: "sss",
        name: "faq",
        component: () => import("../views/faq/Index.vue")
      },
      {
        path: "insanlik-yararina-teknoloji-yarismasi",
        name: "competition",
        component: () => import("../views/competition/Index.vue")
      }
    ]
  },
  {
    path: "",
    component: () => import("../layouts/account/Index.vue"),
    children: [
      {
        path: "/giris",
        name: "sign-in",
        component: () => import("../views/account/SignIn.vue")
      },
      {
        path: "/parolami-unuttum",
        name: "forgot-password",
        component: () => import("../views/account/ForgotPassword.vue")
      }
    ]
  },
  {
    path: "/admin",
    component: () => import("../layouts/admin/Index.vue"),
    children: [
      {
        path: "",
        name: "admin-home",
        component: () => import("../views/admin/scholarship/Index.vue")
      },
      {
        path: "burs/basvuru/:id",
        name: "admin-scholarship-app",
        component: () => import("../views/admin/scholarship/App.vue")
      },
      {
        path: "haberler",
        name: "admin-news",
        component: () => import("../views/admin/news/Index.vue")
      },
      {
        path: "haber-ekle",
        name: "admin-news-create",
        component: () => import("../views/admin/news/Create.vue")
      },
      {
        path: "haber-duzenle/:id",
        name: "admin-news-edit",
        component: () => import("../views/admin/news/Edit.vue")
      },
      {
        path: "bannerlar",
        name: "admin-banners",
        component: () => import("../views/admin/banners/Index.vue")
      },
      {
        path: "banner-ekle",
        name: "admin-banners-create",
        component: () => import("../views/admin/banners/Create.vue")
      },
      {
        path: "banner-duzenle/:id",
        name: "admin-banners-edit",
        component: () => import("../views/admin/banners/Edit.vue")
      },
      {
        path: "basinda-kahev",
        name: "admin-press",
        component: () => import("../views/admin/press/Index.vue")
      },
      {
        path: "basinda-kahev-ekle",
        name: "admin-press-create",
        component: () => import("../views/admin/press/Create.vue")
      },
      {
        path: "basinda-kahev-duzenle/:id",
        name: "admin-press-edit",
        component: () => import("../views/admin/press/Edit.vue")
      },
      {
        path: "bannerlar-en",
        name: "admin-banners-en",
        component: () => import("../views/admin/banners-en/Index.vue")
      },
      {
        path: "banner-ekle-en",
        name: "admin-banners-create-en",
        component: () => import("../views/admin/banners-en/Create.vue")
      },
      {
        path: "banner-duzenle-en/:id",
        name: "admin-banners-edit-en",
        component: () => import("../views/admin/banners-en/Edit.vue")
      },
      {
        path: "haberler-en",
        name: "admin-news-en",
        component: () => import("../views/admin/news-en/Index.vue")
      },
      {
        path: "haber-ekle-en",
        name: "admin-news-create-en",
        component: () => import("../views/admin/news-en/Create.vue")
      },
      {
        path: "haber-duzenle-en/:id",
        name: "admin-news-edit-en",
        component: () => import("../views/admin/news-en/Edit.vue")
      }
    ]
  },
  {
    path: "/en",
    component: () => import("../layouts/en/home/Index.vue"),
    children: [
      {
        path: "",
        name: "en-home",
        component: () => import("../views/en/home/Index.vue")
      },
      {
        path: "donation",
        name: "en-donation",
        component: () => import("../views/en/donation/Index.vue")
      },
      {
        path: "news",
        component: () => import("../layouts/en/news/Index.vue"),
        children: [
          {
            path: "",
            name: "en-news",
            component: () => import("../views/en/news/Index.vue")
          },
          {
            path: ":urlCode",
            name: "en-news-detail",
            component: () => import("../views/en/news/Detail.vue")
          }
        ]
      }
    ]
  },
  {
    path: "/test/null-member-apps",
    name: "test-null-member-apps",
    component: () => import("../views/test/NullMemberApps.vue")
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../views/404.vue")
  }
];

const userCanAccess = function(to: Route, isAdminRoute: boolean) {
  const userSignedIn = store.getters["auth/userSignedIn"];
  const user = store.getters["auth/user"];
  const authRole = to.meta ? to.meta.authRole : null;
  const isAdmin = userSignedIn ? user.roles.indexOf("admin") >= 0 : false;

  // Eğer Route /admin içindeyse authRole'a bakmadan
  // kullanıcının admin rolünde olup olmadığını sorgula
  if (isAdminRoute) {
    if (isAdmin) {
      return true;
    } else {
      return false;
    }
  }

  // Kullanıcı oturum açmış mı?
  if (userSignedIn) {
    // Route için bir rol belirlenmiş ise izin ver
    // Yoksa kullanıcının rolünü kontrol et
    if (!authRole) {
      return true;
    } else {
      // Admin ise izin ver
      if (isAdmin) {
        return true;
      }
      // Kullanıcı istenilen yetki rolünde mi?
      else {
        return user.roles.indexOf(authRole.toLowerCase()) >= 0;
      }
    }
  } else {
    return false;
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth"
      };
    } else {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0, behavior: "smooth" };
      }
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const isAdminRoute = /^\/admin/.test(to.path);
  const requiresAuth =
    to.matched.some(record => record.meta.requiresAuth) || isAdminRoute;

  if (requiresAuth) {
    if (userCanAccess(to, isAdminRoute)) {
      next();
    } else {
      next({
        name: "sign-in",
        query: { returnUrl: to.path }
      });
    }
  } else {
    next();
  }
});

export default router;
