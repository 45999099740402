import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vue-meta";
import "./plugins/vue-notification";
import firebase from "@/plugins/firebase";
import "@/types";
import "@/plugins/json-excel";

Vue.config.productionTip = false;

// Check firebase auth state change
firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    // Auto sign-in
    await store.dispatch("auth/signIn", {
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      firstName: null,
      lastName: null,
      roles: null
    } as User);
  } else {
    // Sign-out
    await store.dispatch("auth/signOut");
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    beforeCreate: () => {
      // Member auto signin
      const member = localStorage.getItem("member");
      if (member) {
        store.dispatch("scholarship/signIn", JSON.parse(member));
      }
    }
  }).$mount("#app");
});
